import 'owl.carousel'


document.addEventListener('DOMContentLoaded', function () {
    $('.projects').owlCarousel({
        margin: 10,
        items: 3,
        dots: true,
        stagePadding: 0,
        loop: true,
        nav: true,
        navText: ["<span class='long-arrow is-triangle arrow-bar is-left'></span>", "<i class='long-arrow is-triangle arrow-bar is-right'></i>"],
        responsive: {
            0: {
                items: 1,
            },
            992: {
                items: 2,
            },
            1200: {
                items: 4,
            },
            1400: {
                items: 4,
            }
        }
    });
    $('.testimonials').owlCarousel({
        margin: 40,
        center: true,
        items: 4,
        dots: true,
        stagePadding: 0,
        loop: true,
        navText: ["<span class='long-arrow is-triangle arrow-bar is-left'></span>", "<i class='long-arrow is-triangle arrow-bar is-right'></i>"],
        responsive: {
            0: {
                items: 2,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 4,
                margin: 20,
            },
            1401: {
                items: 5,
                margin: 20,
            }
        }
    });

});
